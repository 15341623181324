//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Toast } from "vant";
import defaultImg from '@/assets/shopinspect/note.png'
export default {
  name: "Home",
  data() {
    return {
      defaultImg:defaultImg,
      fileList:[],
      imageHost:'https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com/',
      title: "",
      scanCode: "",
      storeOption: [],
      currentStore: "",
      loading: false,
      finished: false,
      leaderSearch: "",
      noteId:0,
      orderId: 0,
      paperItems:[]
    };
  },
  created() {},
  activated() {
    this.noteId = this.$route.query.noteId ? this.$route.query.noteId : 0;
    this.orderId = this.$route.query.orderId ? this.$route.query.orderId : 0;
    this.title = this.$route.query.title ? this.$route.query.title : '';
    window.document.title =  this.title;
    this.initLoad()
    window.document.title =  this.title;
  },
  methods: {
    oversizeFile(){
       this.$q.notify({
          color: "red-5",
          textColor: "white",
          icon: "warning",
          position: "center",
          message: '上传图片大小不可大于10m！',
        });
    },
    gotoPage(url){
      this.$router.push(url)
    },
    deleteNoteImg(item){
      console.log(item);
      this.axios.get(
        "/" + this.$ajaxPrefix.consumer +
        "/store/patrol/order/noteimg/del?id=" + item.id,
      )
      .then((res) => {
        console.log(res)
        if (res.data &&  res.data.data && res.data.code == 0) {
          console.log('noteimg/create',res.data.data)
          Toast("删除成功");
          this.getNoteimg(this.orderId)
        } else {
          this.$q.notify({
            color: "red-5",
            textColor: "white",
            icon: "warning",
            position: "center",
            message: res.data.msg,
          });
        }
      })
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      file.status ='uploading';
      file.message = '上传中...';
      let formData = new FormData();
      formData.append('file',file.file)
       this.axios
          .post(
             "/" + this.$ajaxPrefix.consumer +
           "/oss/uploadImg",
           formData
          )
          .then((res) => {
            console.log(res)
            if (res.data &&  res.data.data && res.data.code == 0) {
              console.log('uploadImg',res.data.data)
              file.url = res.data.data.urlPath;
              file.objectName = res.data.data.objectName;
              this.axios
              .post(
                "/" + this.$ajaxPrefix.consumer +
                "/store/patrol/order/noteimg/create",
                {
                  content:file.objectName,
                  orderId:this.orderId,
                  paperId:this.paperId,
                  noteId:this.noteId,
                  id:file.id
                }
              )
              .then((res) => {
                console.log(res)
                if (res.data &&  res.data.data && res.data.code == 0) {
                  console.log('noteimg/create',res.data.data)
                  Toast("上传成功");
                  file.status ='';
                  file.message = '';
                  this.getNoteimg(this.orderId)
                } else {
                  this.$q.notify({
                    color: "red-5",
                    textColor: "white",
                    icon: "warning",
                    position: "center",
                    message: res.data.msg,
                  });
                }
              })
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          })
    },
    onSubmit() {
      console.log("onSubmit");
      this.$router.push(
        "/stockcheck/detail?code=" +
          this.productCode.toUpperCase() +
          "&shop=" +
          this.currentStore
      );
    },
    onLoad() {
      this.loading = false;
      // 数据全部加载完成
      this.finished = true;
    },
    //获取巡检表信息与试卷id
    initLoad() {
      if (this.orderId > 0) {
        this.axios
          .get(
             "/" + this.$ajaxPrefix.consumer +
           "/store/patrol/order/patrol/getOrder?id="+this.orderId
          )
          .then((res) => {

            if (res.data && res.data.code == 0) {
              console.log('initLoad',res.data)
              this.paperId = res.data.data.paperId
               this.getPaper(res.data.data.paperId)
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          })
          this.getNoteimg(this.orderId)
      }
    },
    //获取试卷
    getPaper(id) {
        this.axios
          .get(
             "/" + this.$ajaxPrefix.consumer +
           "/store/patrol/paper/get?paperId="+id
          )
          .then((res) => {

            if (res.data && res.data.code == 0) {
              console.log('getPaper',res.data)
              this.paperItems = res.data.data.filter(item =>{
                return item.noteId == this.noteId
              })
               this.getAnswers()
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          })
          .catch(() => {
            this.loading = false;
            Toast("请求出错了");
          });
    },
    //获取答案列表
    getAnswers() {
        this.axios
          .get(
             "/" + this.$ajaxPrefix.consumer +
           "/store/patrol/answer/getPaperQuestion?orderId="+this.orderId
          )
          .then((res) => {

            console.log('getPaperQuestion',res.data)
            if (res.data && res.data.code == 0) {
              if(res.data.data && res.data.data.answerList){
                this.paperItems.map(question =>{
                  question.answer = '未答';
                  res.data.data.answerList.map(answer =>{
                    if(question.qtypeId == 1){
                      question.questionItems.map((questionItem,index)=>{
                        if(answer.questionId == question.id && answer.content == questionItem.id){
                          // question.answer = questionItem.itemName;
                          question.answer = '已答';
                        }
                      })
                    }else if(question.qtypeId == 10){
                      if(answer.questionId == question.id){
                        question.answer = '已答';
                      }
                    }
                   
                  })
                })
                this.$forceUpdate() 
              }
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          })
    },
    //获取分类图片
    getNoteimg(id) {
        this.axios
          .post(
             "/" + this.$ajaxPrefix.consumer +
           "/store/patrol/order/noteimg/list",{
              "pager": {
                "pageIndex": 1,
                "pageSize": 100,
                "rowsCount": 0,
                "start": 0
              },
              "queryMap": {
                "orderId": id,
                "noteId":this.noteId
              },
              "queryMapObject": {}
            }
          )
          .then((res) => {

            if (res.data && res.data.code == 0) {
              console.log('noteimg',res.data)
              if(res.data.data && res.data.data.list){
                this.fileList = []
                res.data.data.list.map(v =>{
                  this.fileList.push({
                    url:this.imageHost + v.content,
                    objectName:v.content,
                    id:v.id
                  })
                })
              }
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          })
    },
  },
};
